import { Backdrop, CircularProgress, FormHelperText, Grid, Input, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import DateRangeComponent from '../../components/DateRangePicker'
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getOptimizerHistoryService } from '../../services/optimizerHistory.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

//import all modals and libraries required


function OptimizationForm({ handleCustomerName, handleStartDateChange, handleEndDateChange }) {

    const handleRadiusChange = (event) => {
        localStorage.setItem('radius', event.target.value)
    }

    const formData = useSelector((state) => state.finalReducers.form_details);

    const gasolineCodesCheck = useSelector((state) => state.finalReducers.gasoline_codes)

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true)
        if (gasolineCodesCheck && gasolineCodesCheck[0] && gasolineCodesCheck[0].length !== 0) {
            setLoader(false)
        }
    }, [gasolineCodesCheck])

    const navigate=useNavigate();
    useEffect(async ()=>{
        toast.configure();
        const loginData = JSON.parse(localStorage.getItem('loginData'));
        const accessToken = loginData && loginData.accessToken;
        const data=await getOptimizerHistoryService(accessToken).then((data) => {
           return data;
        })
            .catch((err) => {
                toast.error('Something went wrong, Please refresh', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
                navigate('/login')
            });
            localStorage.setItem('gasSelectAll',true)
            localStorage.setItem('dieselSelectAll',true)
            localStorage.setItem('lpgSelectAll',true)
            localStorage.setItem('aviationSelectAll',true)
            localStorage.setItem('providersSelectAll',true)
    }, [])


    return (
        <>
            <Grid>
                <Grid sx={{ marginTop: '10%', }}>
                    <TextField onChange={handleCustomerName} required variant="standard" sx={{
                        minWidth: '200px',
                        '& label.Mui-focused': {
                            color: 'black',
                            fontWeight: 'bold'
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: '#cc0000',
                        },
                    }} focused placeholder='Enter Customer Name' defaultValue={formData.customerName} />

                </Grid>

                {/* <Grid sx={{ marginTop: '5%', }}>
                    <FormHelperText>Radius</FormHelperText>
                    <Input
                        id="outlined-adornment-weight"
                        defaultValue={formData.radius}
                        size='small'
                        type='number'
                        onChange={(event) =>
                            event.target.value < 0
                                ? (event.target.value = 0)
                                : handleRadiusChange(event)
                        }
                        endAdornment={<InputAdornment position="end">Miles</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'miles',
                        }}
                    />
                </Grid> */}

                <Grid sx={{ margin: '10% 0% 5% 0%', }}>
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>Transaction List Timeline</Typography>
                    <Grid sx={{ display: 'flex' }} columnGap={2}>
                        <DateRangeComponent label='Start Date' handleDateChange={handleStartDateChange} />
                        <DateRangeComponent label='End Date' handleDateChange={handleEndDateChange} />
                    </Grid>
                </Grid>
            </Grid>
            {loader &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

        </>
    )
}

export default OptimizationForm