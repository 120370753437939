import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSelector } from 'react-redux';

export default function DateRangeComponent({label,handleDateChange}) {
 
  const color = "#cc0000";

  const handleChange=(e)=>{
    handleDateChange(e);
  }

  const startDate=useSelector((state) => state.dateReducer.startDate);
  const endDate=useSelector((state) => state.dateReducer.endDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={label=='Start Date'?startDate:endDate}
        onChange={handleChange}
        renderInput={(params) => <TextField size="small" style={{
          marginTop: '3%',
          width:'20%'
        }} sx={{
          svg: { color },
          input: { color },
          label: { color },
        }} {...params} />}
      />
    </LocalizationProvider>
  );
}
