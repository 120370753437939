import { LOGIN_USER } from "../actions/actionTypes";

const initialState = {
    login_data : {},
}

const loginUserReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case LOGIN_USER:
            return {
                ...state, login_data:payload
            }
        default:
            return state;
    }
}


export default loginUserReducer