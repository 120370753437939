import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import SearchComponent from '../../components/SearchComponent';
import { getCustomerAction } from '../../redux/actions/optimizationFormAction';
import { useDispatch, useSelector } from 'react-redux';
import { notificationService } from '../../services/sendNotification.service'
import { toast } from 'react-toastify';
import { SafetyCheckRounded } from '@mui/icons-material';


function NotificationsForm({ open, handleCloseForm }) {

  const notificationDataRedux = useSelector((state => state.finalReducers.customer_data))
  const handleCrossClose = () => {
    setActiveStep(0)
    handleCloseForm()
  }

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    console.log(customersList)
    let flag=false;
    customersList.map((data)=>{
      if(data.checked===true){
        flag=true;
        return;
      }
    })

    if(flag===true){
      setActiveStep(1);
    }
    else{
      setActiveStep(0)
    }
  }

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  }

  const [selectAll, setSelectAll] = useState(true);
  const [selectAllProviders, setSelectAllProviders] = useState(JSON.parse(localStorage.getItem('customerSelectAll')) === true);


  const [customersList, setCustomersList] = useState(notificationDataRedux)
  useEffect(() => {
    setCustomersList(customersList)
  }, [selectAll])


  const [filteredResults, setFilteredResults] = useState(customersList)
  const handleFilteredResults = (filteredData) => {
    setFilteredResults(filteredData)
  }

  const [segmentSelected,setSegmentSelected]=useState('')

  const dispatch = useDispatch();
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setSelectAll(true)
      setSelectAllProviders(true);
      setSegmentSelected('Subscribed Users')
      customersList.map((data) => {
        data.checked = true;
      })
      setCustomersList(customersList)
      localStorage.setItem('customerSelectAll', true)
      dispatch(getCustomerAction(customersList))
    }
    else if (e.target.checked === false) {
      setSelectAll(false)
      setSelectAllProviders(false);
      customersList.map((data) => {
        data.checked = false;
      })
      setCustomersList(customersList)
      localStorage.setItem('customerSelectAll', false)
      dispatch(getCustomerAction(customersList))
    }
    setFilteredResults(customersList)
  }


  const [searchInput, setSearchInput] = useState('');
  const handleSearchInput = (data) => {
    setSearchInput(data)
  }


  const [checked, setChecked] = useState(false)
  const handleCodeChange = (e, name) => {
    setChecked(e.target.checked)
    setChecked(!checked)
    if (e.target.checked == true) {
      customersList.map((data) => {
        if (data.name === name) {
          data.checked = true;
        }
      })
      dispatch(getCustomerAction(customersList))
      setCustomersList(customersList)
    }
    else if (e.target.checked == false) {
      customersList.map((data) => {
        if (data.name === name) {
          data.checked = false;
        }
      })
      dispatch(getCustomerAction(customersList))
      setCustomersList(customersList)
    }
  }




  const [loader, setLoader] = useState(false);

  const handleSendNotification = async () => {
    toast.configure();
    if (title == '' || message == '' || (title == 'test' && message == 'test')) {
      toast.error("Please enter all the fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      });
      return;
    }
    let userIDsArray = [];
    notificationDataRedux.map((data) => {
      if (data.checked == true && selectAll === false) {
        userIDsArray.push(data.id);
      }
    })
    setLoader(true)
    const notificationData = {
      title: title,
      segments: segmentSelected,
      filters: "",
      customerIDs: userIDsArray,
      imagePath: "",
      url: "",
      message: message

    }
    await notificationService(notificationData).then((res) => {
      if (res.status === true) {
        setLoader(false)
        handleCloseForm();
        toast.success("Notification send success", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        });
        window.location.reload();
      }
    })
      .catch((err) => {
        setLoader(false)
        toast.error('Something went wrong !!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        });
      });

      notificationDataRedux.map((data)=>{
        data.checked=true
      })
      dispatch(getCustomerAction(customersList))
  }

  // useEffect(async () => {
  //   const loginData = JSON.parse(localStorage.getItem('loginData'));
  //   const accessToken = loginData && loginData.accessToken;
  //   setLoader(true)
  //   const allProviders = await getAllProvidersService(accessToken).then((data) => {
  //     setLoader(false)
  //     return data;
  //   })
  //   const newData= allProviders.data.map((data)=>{
  //     data['checked'] = true
  //   })
  //   dispatch(getCustomerAction(newData))
  //   setCustomersList(allProviders.data)
  // }, [])


  return (
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <strong>Create Message</strong>
      </DialogTitle>
      <DialogContent>
        {activeStep == 0 &&
          <Grid>
            <Grid sx={{ display: 'flex' }} columnGap={30}>
              {/* <Typography>Select Groups</Typography> */}
              {/* <FormControl>
              <FormControlLabel control={<Checkbox
                defaultChecked={true}
                onChange={(e) => handleSelectAll(e)}
                style={{ color: '#cc0000', marginLeft: '100%' }}
              />} label={<span style={{ fontSize: 14, textAlign: 'right' }}>SelectAll</span>} />
            </FormControl> */}
            </Grid>

            <Grid sx={{ marginTop: '5%', display: 'flex' }}>
              <Grid>
                <FormControl >
                  <FormControlLabel control={<Checkbox
                    style={{ color: '#cc0000' }}
                    checked={selectAll}
                    onChange={(e) => handleSelectAll(e)} />} label={<span style={{ fontSize: 14 }}>Select All</span>} />
                </FormControl>
              </Grid>
              <Grid sx={{ marginLeft: 'auto' }}>
                <SearchComponent handleFilteredResults={handleFilteredResults} handleSearchInput={handleSearchInput} productCodes={customersList} />
              </Grid>
            </Grid>
            <Box
              sx={{ width: '100%', bgcolor: 'background.paper', overflowY: 'scroll', height: '300px', border: '1px solid black', padding: '5%' }}
            >
              {searchInput.length > 1 ? (
                filteredResults.map((data) => {
                  return (
                    <>
                      <FormControl>
                        <FormControlLabel control={<Checkbox
                          //         checked={selectAll==true?selectAll:data.checked}
                          checked={data.checked}
                          onChange={(e) => handleCodeChange(e, data.name)}
                          style={{ color: '#cc0000' }}
                        />} label={<span style={{ fontSize: 14, textAlign: 'right' }} sx={{ width: '400px' }}>{data.name}</span>} />
                      </FormControl>
                      <Divider />
                    </>
                  )

                })
              ) : (
                customersList && customersList.map((data) => {
                  return (
                    <>
                      <FormControl>
                        <FormControlLabel control={<Checkbox
                          //         checked={selectAll==true?selectAll:data.checked}
                          checked={data.checked}
                          onChange={(e) => handleCodeChange(e, data.name)}
                          style={{ color: '#cc0000' }}
                        />} label={<span style={{ fontSize: 14, textAlign: 'right' }} sx={{ width: '400px' }}>{data.name}</span>} />
                      </FormControl>
                      <Divider />
                    </>
                  )
                })
              )
              }


            </Box>
            {/* <MultiSelectDropdown mockData={customersList}/> */}

          </Grid>
        }
        {activeStep == 1 &&
          <Grid sx={{ display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
            <TextField
              id="outlined-title"
              label="Title"
              margin="normal"
              inputProps={{ maxLength: 50 }}
              onChange={handleTitleChange}
              variant="outlined"
              helperText={<span style={{color:'#cc0000'}}>{title.length+'/50 characters left'}</span>}
            />
            <TextField
              id="outlined-message"
              label="Message"
              margin="normal"
              onChange={handleMessageChange}
              inputProps={{ maxLength: 1000 }}
              variant="outlined"
              multiline
              helperText={<span style={{color:'#cc0000'}}>{message.length+'/1000 characters left'}</span>}
              rows={5}
            />
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        {activeStep === 1 &&
          <Button onClick={() => setActiveStep(0)} sx={{
            textTransform: 'none', backgroundColor: '#A9A9A9', color: 'white', "&:hover": {
              backgroundColor: "#A9A9A9"
            },
            marginRight: '57%'
          }}>
            Back
          </Button>
        }
        <Button onClick={handleCrossClose} sx={{
          textTransform: 'none', backgroundColor: '#A9A9A9', color: 'white', "&:hover": {
            backgroundColor: "#A9A9A9",
          },
        }}>
          Close
        </Button>
        <Button onClick={activeStep == 0 ? handleNext : handleSendNotification} autoFocus component="label" sx={{
          textTransform: 'none', backgroundColor: '#cc0000', color: 'white', "&:hover": {
            backgroundColor: "#cc0000",
          },
          marginLeft: '3%',
          width: '80px'
        }}>
          {activeStep == 0 ? 'Next' : 'Send'}
        </Button>
      </DialogActions>
      {loader &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }

    </Dialog>
  )
}

export default NotificationsForm