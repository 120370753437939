import { AVIATIONFUEL_CODES, DIESEL_CODES, GASOLINE_CODES, LPG_CODES } from "./actionTypes"

export const gasolineCodeAction=(data)=>{
    return{
        type:GASOLINE_CODES,
        payload:data
    }
}

export const dieselCodeAction=(data)=>{
    return{
        type:DIESEL_CODES,
        payload:data
    }
}

export const lpgCodeAction=(data)=>{
    return{
        type:LPG_CODES,
        payload:data
    }
}


export const aviationFuelCodeAction=(data)=>{
    return{
        type:AVIATIONFUEL_CODES,
        payload:data
    }
}



