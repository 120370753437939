import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useMediaQuery } from 'react-responsive';
import OptimizationSummary from '../OptimizationModal/OptimizationSummary';
import { useEffect } from 'react';
import { getOptimizerHistoryService } from '../../services/optimizerHistory.service';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import OptimizationModal from '../OptimizationModal/OptimizationModal';

//import all modals and libraries required

function CostOptimizer() {

    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const [optimizerHistoryData, setOptimizerHistoryData] = useState([]);
    const [loader, setLoader] = React.useState(false);
    const navigate = useNavigate();

    var styles = {
        button: {
            color: '#ffffff', backgroundColor: '#cc0000',fontWeight:'bold', textTransform: 'none', padding: '0.5% 1% 0.5% 1%', border: '1px solid red', marginTop: '-1%'
        }
    }


    useEffect(async () => {
        toast.configure();
        const accessToken = loginData && loginData.accessToken;
        setLoader(true)
        const data = await getOptimizerHistoryService(accessToken).then((data) => {
            setOptimizerHistoryData(data);
        })
            .catch((err) => {
                toast.error(err.response.data.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
                setLoader(false)
                navigate('/login')
            });
        setLoader(false)
    }, [])
    //Sample card data 
    //responsiveness for card when width less than 900px
    const cardResponsive = useMediaQuery({ query: '(max-width: 900px)' })

    //Open Card Modal
    const [openModal, setOpenModal] = useState(false);
    const [openOptimizeModal, setOptimizeModal] = useState(false)
    const [modalContent, setModalContent] = useState({});
    const handleCardClick = (data) => {
        setOpenModal(true);
        setModalContent(data);
    }
    const handleOptimization = () => {
        setOptimizeModal(true)
    }


    const handleClose = () => {
        setOpenModal(false);
    }

    const handleOptimizeClose = () => {
        setOptimizeModal(false)
    }

    //Map the card data
    const renderCard = optimizerHistoryData.length ? (optimizerHistoryData.map((data) => {
        const startDate = moment(data.startDate, 'YYYY/MM/DD');
        const endDate = moment(data.endDate, 'YYYY/MM/DD');
        data['date'] = startDate.format('MM/DD/YYYY') + ' - ' + endDate.format('MM/DD/YYYY')
        return (
            <React.Fragment>
                <CardContent style={{ border: '1px solid grey' }} onClick={() => handleCardClick(data)}>
                    <Grid sx={{ display: 'flex' }}>
                        <Grid>
                            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }} gutterBottom>
                                {data.customerName}
                            </Typography>
                            <Typography sx={{ fontSize: 15 }} color="text.secondary">{data.date}</Typography>
                        </Grid>
                        <Grid sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }} columnGap={5}>
                            <Typography sx={{ fontSize: 15 }} color="text.secondary">Anticipated Savings</Typography>
                            <Button variant='filled' sx={{
                                borderRadius: 20, backgroundColor: '#cc0000', color: 'white', "&:hover": {
                                    backgroundColor: "#cc0000",
                                },
                            }}>${data.productSavings.reduce((a, b) => (a + b.savings), 0).toFixed(2)}</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </React.Fragment>
        )
    })) : (
        <Grid sx={{ marginTop: '15%' }} container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <CircularProgress sx={{ color: '#cc0000' }} />
        </Grid>
    )


    return (
        <>
            <Typography variant='h5' sx={{ marginTop: '2%' }}><strong>Cost Optimizer</strong></Typography>
            <Grid container justifyContent="flex-end" sx={{ marginTop: '-2%' }}>
                <Button style={styles.button} onClick={handleOptimization}>New Optimization</Button>
            </Grid>
            <Grid style={{ margin: '3% 0% 5% 5%' }}>
                <Box sx={{ width: cardResponsive ? '95%' : '90%', marginTop: '1%', boxShadow: 4, overflowY: 'scroll', height: '67vh' }}>
                    <Card variant="outlined" >{renderCard}</Card>
                    {/* {loader &&
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loader}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    } */}

                </Box>
            </Grid>
            {/* Open Optimization History Modal when clicked on Modal */}
            <OptimizationSummary open={openModal} modalContent={modalContent} handleClose={handleClose} />
            <OptimizationModal open={openOptimizeModal} handleClose={handleOptimizeClose} />
        </>
    )
}

export default CostOptimizer
