import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationModal({ open, handleChange }) {
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p style={{fontSize:'17px'}}><strong>Are you sure you want to cancel Optimization Process?</strong></p>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleChange('no')} sx={{
            textTransform: 'none', backgroundColor: '#A9A9A9', color: 'white', "&:hover": {
              backgroundColor: "#A9A9A9",
            },
          }}>Disagree</Button>
          <Button onClick={() => handleChange('yes')} autoFocus component="label" sx={{
            textTransform: 'none', backgroundColor: '#cc0000', color: 'white', "&:hover": {
              backgroundColor: "#cc0000",
            },
            marginLeft: '3%',
            width: '80px'
          }}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
