import { Typography } from '@mui/material';
import React from 'react'
import TopNavBar from './TopNavBar/TopNavBar';

function DataStudioReport() {

    function Iframe(props) {
        return (
            <div
                dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
            />
        );
    }

    return (
        <>
        <Typography variant='h5' sx={{ marginTop: '1%',marginBottom:'2%' }}><strong>Report</strong></Typography>
        <Iframe iframe={'<iframe width="100%" height="600px" src="https://datastudio.google.com/embed/reporting/f2d92871-5af3-46d4-bfbb-5c107269f797/page/p_elbw6ttlzc" frameborder="0" style="border:0" allowfullscreen></iframe>'} allow="autoplay" />
        </>
    )
}

export default DataStudioReport