import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import Auth from '../../helpers/Auth';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import { useMediaQuery } from 'react-responsive';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyOtpModal from './VerifyOtpModal';
import { changePasswordService } from '../../services/forgotPassword.service';

//Create styles for Login Page
var styles = {
    loginGrid: {
        color: 'black', backgroundColor: '#F0F0F0', margin: '5% -10% 0% -70%', padding: '2% 4% 5% 4%', borderRadius: 20
    },

    loginButton: {
        textTransform: 'none', backgroundColor: 'red', color: 'white', width: '87%', marginTop: '10%'
    },
}


function PasswordReset() {

    const {state} = useLocation();
    const { email } = state; 
    console.log("email", email)

    let navigate = useNavigate();

    // responsiveness variable for loginScreen
    const loginModal = useMediaQuery({ query: '(max-width: 900px)' })
    const loginButtonWidth = useMediaQuery({ query: '(min-width: 1345px)' })

    const handleResetPassword = () => {
        //Use react toastify to show errors
        toast.configure();
        if (!userCredentials.password || !userCredentials.email) {
            toast.error("Please Enter all the fields!", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 2000
            });
        }
        else {
            changePasswordService({ email_address: userCredentials.email, password: userCredentials.password })
                .then((response) => {
                    if (response.status == true) {
                        toast.success("Password Updated Successfully!", {
                            position: toast.POSITION.TOP_LEFT,
                            autoClose: 2000
                        });
                        navigate('/login')
                    }
                    else {
                        toast.error("Something went wrong, Please refresh", {
                            position: toast.POSITION.TOP_LEFT,
                            autoClose: 2000
                        });
                    }
                })
                .catch((err)=>{
                    toast.error(err.message, {
                        position: toast.POSITION.TOP_LEFT,
                        autoClose: 2000
                    });
                })
        }

    }


    const [userCredentials, setUserCredentials] = useState({
        email: '',
        password: '',
    });

    //Storing email and password in userCredentials
    const handleChange = (e) => {
        setUserCredentials({ ...userCredentials, [e.target.name]: e.target.value })
    }

    const [openOtpModal, setOpenOtpModal] = useState(true);

    const handleCloseDialog = () => {
        setOpenOtpModal(false);
    }

    return (
        <>
            <Grid sx={{ display: 'flex' }} item xs={12}>
                <Grid>
                    <img src='backgroundImage.jpeg' width={loginModal ? '280%' : '90%'} height='95%' />
                </Grid>
                <Grid sx={{ margin: loginModal ? '3% 22% 0% 2%' : '3% 2% 0% 2%', paddingBottom: loginModal ? '30%' : '' }}>
                    <img src='appstore.png' width={120} height={120} style={{ margin: '0% 0% 5% -20%' }} />
                    <Grid style={styles.loginGrid}>
                        <Typography sx={{ fontWeight: 'bold', margin: '10% 0% 0% 0%', fontSize: '20px', textAlign: 'center' }}>Reset Password</Typography>
                        <Grid sx={{ margin: '15% 0% 0% 6.5%' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
                            <TextField placeholder='Enter your email id' type='email' size='small' onChange={handleChange}
                                name='email' InputProps={{
                                    sx: {
                                        "& .MuiInputBase-input": {
                                            background: "white !important"
                                        },
                                        width: loginModal ? '90%' : '100%'
                                    }
                                }} required></TextField>
                        </Grid>

                        <Grid sx={{ margin: '5% 0% 0% 6.5%' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>New Password</Typography>
                            <TextField placeholder='Password' type='password' size='small' onChange={handleChange}
                                name='password' InputProps={{
                                    sx: {
                                        "& .MuiInputBase-input": {
                                            background: "white !important"
                                        },
                                        width: loginModal ? '90%' : '100%'
                                    }
                                }} required></TextField>
                        </Grid>

                        <Button sx={{ margin: '10% 0% 15% 6.5%', width: loginButtonWidth ? '50% !important' : '87% !important' }} style={styles.loginButton} variant='filled' onClick={handleResetPassword}>Update</Button>
                    </Grid>
                </Grid>
            </Grid>

            <VerifyOtpModal open={openOtpModal} email={email} handleCloseDialog={handleCloseDialog} />
        </>
    )
}

export default PasswordReset