import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React from 'react'
import { DisplayTable } from '../../components/DisplayTable'
import {Link} from 'react-router-dom';
//import all modals and libraries required


function OptimizationSummary({ open, modalContent, handleClose }) {
    return (
        <div>
            <Dialog
                fullWidth={true}
                open={open}
                    onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle style={{ fontWeight: 'bold' }} id="responsive-dialog-title">
                    Optimization Summary
                </DialogTitle>
                <DialogContent style={{ margin: '3% 3% 3% 3%' }}>
                    <Grid>
                        <Typography sx={{fontSize:15}}><strong>Customer Name</strong> : {modalContent.customerName}</Typography>
                    </Grid>
                    <Grid>
                        <Typography sx={{ marginTop: '2%',fontSize:15 }}><strong>Transaction timeline</strong> : {modalContent.date}</Typography>
                    </Grid>
                    <Grid style={{ marginTop: '10%' }}><DisplayTable modalContent={modalContent}/></Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} sx={{
                        textTransform: 'none', backgroundColor: '#A9A9A9', color: 'white', "&:hover": {
                            backgroundColor: "#A9A9A9",
                        },
                    }}>
                        Close
                    </Button>
                    <Button autoFocus component="label" sx={{
                        textTransform: 'none', backgroundColor: '#cc0000', color: 'white', "&:hover": {
                            backgroundColor: "#cc0000",
                        },
                        marginLeft: '5%'
                    }}>
                    <a style={{ color: 'white', textDecoration: 'none' }} href={modalContent.outputFileURL}> Download Excel</a>
                    </Button>

                </DialogActions>


            </Dialog>
        </div>
    )
}

export default OptimizationSummary