import { VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

function SearchComponent({ handleFilteredResults, handleSearchInput, productCodes }) {
    const searchItems = (searchValue) => {
        setSearch(searchValue)
        handleSearchInput(searchValue);
        const filteredData = productCodes.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
        })
        handleFilteredResults(filteredData);
    }

    const [search, setSearch] = useState('')
    const clearSearch = () => {
        setSearch('')
        searchItems('')
    }
    return (
        <TextField
            id="outlined-adornment-password"
            type='text'
            size='small'
            value={search}
            variant="standard"
            onChange={(e) => searchItems(e.target.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            size='small'
                            onClick={clearSearch}
                        >
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
            placeholder='Search'
            sx={{
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#cc0000',
                },
            }}
        />
    )
}

export default SearchComponent