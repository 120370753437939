import axios from "axios";
import { BACKEND_URL } from "../constants/constants";
export  function getOptimizerHistoryService(accessToken) {
    return new Promise(function (resolve, reject) {
        axios.get(BACKEND_URL+'/api/v2/admin/analysis/recents',{
            headers: {
                'Authorization': "Bearer ".concat(accessToken)
              }
        })
            .then((res) => {
                resolve(res.data.data);
            })
            .catch((err) => {
                reject(err);
                throw err;
            })
    })
}