import { Backdrop, Button, CircularProgress, Grid, Typography } from '@mui/material'
import React from 'react'
import xlsxParser from "xlsx-parse-json";
import { validateExcel } from '../../helpers/excelValidations';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fileSelectedAction } from '../../redux/actions/optimizationFormAction';
import DownloadIcon from '@mui/icons-material/Download';

const excelFile = require('../../constants/sampleTemplate.xlsx');

function UploadFilesModal({ handleError, handleFileSelection }) {

    const fileSelected = useSelector((state) => state.finalReducers.file_selected)
    const [selectedFile, setSelectedFile] = React.useState(fileSelected);
    const [loader, setLoader] = React.useState(false);
    const [errorFlag, setErrorFlag] = React.useState(false);

    const dispatch = useDispatch();

    const onSelectFile = e => {
        setLoader(true);
        toast.configure();
        if (!e.target.files || e.target.files.length === 0) {
            setLoader(false);
            toast.error("Please Upload the Excel file!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
            setSelectedFile(undefined)
            return
        }
        setSelectedFile(e.target.files[0].name);

        const file = e.target.files[0];

        xlsxParser.onFileSelection(file).then((data) => {
            console.log('!!!!!!!!!!!',data)
            localStorage.setItem('FileUploaded', file);
            handleError(false)
            handleFileSelection(file);
            let errors = [];
            errors = validateExcel(data[Object.keys(data)[0]]);
            let formatedError = "<lu>";
            let colError = "<lu>";
            if (errors.missingColumns) {
                errors.missingColumns.map((data) => {
                    return colError = colError.concat(`
                    <li style="color:#cc0000;">
                    ${data}
                    </li>`)
                })
            }

            if (errors.length > 0) {
                handleError(true);
                setErrorFlag(true);
                console.log('Errors:', errors)
                formatedError = formatedError.concat(`<p><strong>Errors:</strong></p>`);
                errors.forEach((error) => {
                    if (error.row || error.column) {
                        formatedError = formatedError.concat(`
                        <li>
                        [Line|column] :  [${error.row}|${error.column}]
                        With Value: <strong>${error.value}</strong>
                        Needs: ${error.type}
                        </li>`);
                    } else {
                        formatedError = formatedError.concat(`<b>
                        Format of the uploaded file is incorrect, please review the format and correct it.</b>
                        <br>Following columns are missing in the excel file</br><p></p>
                        ${colError}`);
                    }
                });

                setLoader(false);
            } else {
                handleError(false)
                dispatch(fileSelectedAction(file.name));
                toast.success("File Validated Successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
                setLoader(false);
                formatedError = formatedError.concat(`
            <li>
                Successful validation!
            </li>`);
            }

            formatedError.concat("</lu>");
            document.getElementById("result-id").innerHTML = formatedError;

        });

        // I've kept this example simple by using the first image instead of multiple

    }

    useEffect(() => {
        console.log(errorFlag)
        if (fileSelected !== 'No File Chosen' && errorFlag == false) {
            handleError(false)
        }
        else {
            handleError(true)
        }
    }, [errorFlag])
    return (
        <>
            <Grid sx={{ marginTop: '5%' }}>
                <Typography sx={{ backgroundColor: '#F0F0F0', fontWeight: 'bold', padding: '1% 0% 1% 2%' }}>Upload Transaction Data</Typography>
                <Grid sx={{ border: 'solid 1px black', padding: '10% 0% 10% 5%' }}>
                    <Button autoFocus component="label" sx={{
                        textTransform: 'none', backgroundColor: '#F0F0F0', color: '#000000', "&:hover": {
                            backgroundColor: "#F0F0F0",
                        },
                        marginLeft: '5%'
                    }}>
                        Upload

                        <input type="file" id="excel-file-id" accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" hidden onChange={onSelectFile} />
                    </Button>
                    {/* <Button autoFocus component="label" sx={{
                        textTransform: 'none', backgroundColor: '#F0F0F0', color: '#000000', "&:hover": {
                            backgroundColor: "#F0F0F0",
                        },
                        marginLeft: '1%'
                    }}>
                        Choose File

                        <input type="file" id="excel-file-id" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden onChange={onSelectFile} />
                    </Button> */}
                    <span style={{ marginLeft: '5%', fontSize: '12' }}>{selectedFile}</span>
                    <div style={{ margin: '5% 0% 0% 5%', fontSize: '12' }} id="result-id">Note : *Please upload file in .xlsx format only</div>
                    <Button autoFocus component="label" sx={{
                        textTransform: 'none', backgroundColor: '#cc0000', color: 'white', "&:hover": {
                            backgroundColor: "#cc0000",
                        },
                        marginLeft: '80%',
                        marginBottom: '-10%'
                    }}>
                        <a style={{ color: 'white', textDecoration: 'none' }} href={excelFile}>Template</a><DownloadIcon sx={{fontSize:'20px'}}/></Button>
                </Grid>
            </Grid>

            {loader &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

        </>
    )
}

export default UploadFilesModal