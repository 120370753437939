export const OPTIMIZATION_FORM='OPTIMIZATION_FORM';
export const LOGIN_USER='LOGIN_USER';
export const START_DATE='START_DATE';
export const END_DATE='END_DATE';
export const ADD_NEW_CODE='ADD_NEW_CODE';
export const GASOLINE_CODES='GASOLINE_CODES';
export const DIESEL_CODES='DIESEL_CODES';
export const LPG_CODES='LPG_CODES';
export const AVIATIONFUEL_CODES='AVIATIONFUEL_CODES';
export const PROVIDERS_DISCOUNTS='PROVIDERS_DISCOUNTS';
export const FILE_SELECTED='FILE_SELECTED';
export const GET_CUSTOMERS='GET_CUSTOMERS';
export const SEND_NOTIFICATION='SEND_NOTIFICATION';