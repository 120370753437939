import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const INTEGER = "An Integer value";
const _isInteger = { mask: /^[0-9]*$/ };

const LATLONG = "A Latitude Longitude format (I.e. +90.0, -127.554334)";
const _isLAT = { mask: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/ };
const _isLONG = { mask: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/ };

const ALPHABET = "Alphabets from (a-z | A-Z)"
const _isAlphabet = { mask: /^[a-zA-Z]*$/ };

// const excelMandatoryColumns = [
//     'Merchant Street Address', 'Merchant City','Merchant Name', 'Merchant State','Merchant Postal Code', 'Product Code','Product Qty','Unit Cost','Total Fuel Cost','Net Cost',
// ]
const excelMandatoryColumns = [
    'Address', 'City','Merchant Name', 'State','Zip Code', 'Product Code','Product Qty',
]
// const excelNonMandatoryColumns = [
//     'Merchant County', 'Product Class', 'Unit of Measure' 
// ]
let missingColumns=[];
const checkEqualColumns = (array) => {
    let status = true
    var compare = {} 

    // console.log('Array2:', JSON.stringify(array) )
    excelMandatoryColumns.forEach(mandatory => {
        if (array.includes(mandatory)) {
            // console.log('Includes', mandatory);
        } else {
            missingColumns.push(mandatory);
            status = false;
        }
    });

    // console.log('validation:', status)
    return status;

    

    // if (JSON.stringify(array1) == JSON.stringify(array2))
    //     return true;
    // else
    //     return false;
}

const validUnitZipCode = (row, element, errors) => {
    const UNIT_COLUMN = "Unit";
    const ZIPCODE_COLUMN = "Zipcode";
    let validUnitValue = _isInteger.mask.test(element[UNIT_COLUMN]);
    if (!validUnitValue) {
        let rowError = row + 2;
        errors.push({
            row: rowError,
            column: UNIT_COLUMN,
            type: INTEGER,
            value: element[UNIT_COLUMN]
        });
    }

    let validZipcodeValue = _isInteger.mask.test(element[ZIPCODE_COLUMN]);
    if (!validZipcodeValue) {
        let rowError = row + 2;
        errors.push({
            row: rowError,
            column: ZIPCODE_COLUMN,
            type: INTEGER,
            value: element[ZIPCODE_COLUMN]
        });
    }
};

const validProduct = (row, element, errors) => {
    const COLUMN = "Product";
    let validProductValue = _isAlphabet.mask.test(element[COLUMN]);
    if (!validProductValue) {
        let rowError = row + 2;
        errors.push({
            row: rowError,
            column: COLUMN,
            type: ALPHABET,
            value: element[COLUMN]
        });
    }
};



const validMerchantName = (row, element, errors) => {
    const COLUMN = "Merchant Name";
    let validMerchantValue = _isAlphabet.mask.test(element[COLUMN]);
    if (!validMerchantValue) {
        let rowError = row + 2;
        errors.push({
            row: rowError,
            column: COLUMN,
            type: ALPHABET,
            value: element[COLUMN]
        });
    }
};

const  validLatLong=(row, element, errors)=>{
    const LAT_COLUMN = "Latitude";
    const LONG_COLUMN = "Longitude";
    let latValue = _isLAT.mask.test(element[LAT_COLUMN]);
    let longValue = _isLONG.mask.test(element[LONG_COLUMN]);

    if (!latValue) {
        let rowError = row + 2;
        errors.push({
            row: rowError,
            column: LAT_COLUMN,
            type: LATLONG,
            value: element[LAT_COLUMN]
        });
    }

    
    if (!longValue) {
        let rowError = row + 2;
        errors.push({
            row: rowError,
            column: LONG_COLUMN,
            type: LATLONG,
            value: element[LONG_COLUMN]
        });
    }
}  


export const validateExcel =  (rows) => {
    let errors = [];
    missingColumns=[];
    if (rows.length>0 && checkEqualColumns(Object.keys(rows[0]))) {
    }
    else if(rows.length!==0) {
        errors.push('Not Equal');
        errors['missingColumns']=missingColumns;
        toast.configure();
        toast.error("Please Enter Correct Column Names!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
        });
    }
    for (let i = 0; i < rows.length; i++) {        
        let element = rows[i];
        // validProduct(i, element, errors);
        // validUnitZipCode(i, element, errors);
        // validLatLong(i, element, errors);
        // validMerchantName(i, element, errors);

    }
    return errors;
};
