import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BACKEND_URL } from '../../constants/constants'
import { getCustomerAction } from '../../redux/actions/optimizationFormAction'
import { getAllCustomerService, getAllProvidersService } from '../../services/getProviders.service'
import DataTableComponent from './DataTableComponent'
import NotificationsForm from './NotificationForm'
import NotificationsModal from './NotificationsModal'

function NotificationScreen() {
  var styles = {
    button: {
      color: '#ffffff', backgroundColor: '#cc0000',fontWeight:'bold', textTransform: 'none', padding: '0.5% 1% 0.5% 1%', border: '1px solid red'
    }
  }

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 230,
    },
    {
      field: 'body',
      headerName: 'Message',
      width: 530,
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 230,
      renderCell: (params) => (
        moment(params.row.createdAt).format('DD-MM-YYYY')
      )
    },

    {
      field: 'customersCount',
      headerName: 'Customers Count',
      width: 140,
    },
  ];

  const [rows, setRows] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const handleOnCellClick = (e) => {
    setRowData(e.row);
    setOpenModal(true);
  }

  const handleModalClose = () => {
    setOpenModal(false);
  }

  const handleCloseForm = () => {
    setOpenNotifForm(false);
  }

  const [openNotifForm, setOpenNotifForm] = useState(false);
  const handleNotificationForm = () => {
    setOpenNotifForm(true);
  }

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch()
  useEffect(async () => {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const accessToken = loginData && loginData.accessToken;
    setLoader(true)
    const allProviders = await getAllCustomerService(accessToken).then((data) => {
      setLoader(false)
      return data;
    })
    allProviders.data.map((data) => {
      data['checked'] = true
    })
    dispatch(getCustomerAction(allProviders.data))
  }, [])


  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const accessToken = loginData && loginData.accessToken;
    setLoader(true)
    axios.get(BACKEND_URL + '/api/v2/admin/notifications/getAll?count=0&isUser=0', {
      headers: {
        'Authorization': "Bearer ".concat(accessToken)
      }
    }
    )
      .then((res) => {
        setLoader(false)
        setRows(res.data.data)
      })
  }, [])

  return (
    <>
      <Typography variant='h5' sx={{ marginTop: '1%' }}><strong>Messages</strong></Typography>
      <Grid container justifyContent="flex-end" sx={{ marginTop: '-3%' }}>
        <Button style={styles.button} onClick={handleNotificationForm}>New Message</Button>
      </Grid>
      <Grid style={{ marginTop: '2%' }}>
        <Box style={{ height: '70vh', width: '100%' }}>
          {!loader ? 
          (<DataTableComponent rows={rows} columns={columns} handleOnCellClick={handleOnCellClick} />) : 
          (<Grid sx={{ marginTop: '15%' }} container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <CircularProgress sx={{ color: '#cc0000' }} />
          </Grid>
          )}
        </Box>
      </Grid>

      {openModal && <NotificationsModal open={openModal} handleClose={handleModalClose} data={rowData} />}
      {openNotifForm && <NotificationsForm open={openNotifForm} handleCloseForm={handleCloseForm} />}
      {/* {loader &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      } */}
    </>
  )
}

export default NotificationScreen