import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { rootReducer } from '../../redux/reducers/rootReducer'
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Icon } from '@mui/material';
import SearchComponent from '../../components/SearchComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import CustomCodeModal from './CustomCodeModal';
import { addNewCodeAction } from '../../redux/actions/optimizationFormAction';
import { useDispatch } from 'react-redux';
import { aviationFuelCodeAction, dieselCodeAction, gasolineCodeAction, lpgCodeAction } from '../../redux/actions/codesAction'
import { addProductService } from '../../services/getProducts.service';

function FuelSelectionModal({ customerName, startDate, endDate, type }) {


    const gasolineCodes = useSelector((state) => state.finalReducers.gasoline_codes);
    const dieselCodes = useSelector((state) => state.finalReducers.diesel_codes);
    const LPGCodes = useSelector((state) => state.finalReducers.lpg_codes);
    const aviationFuel = useSelector((state) => state.finalReducers.aviationfuel_codes);


    const [loader, setLoader] = useState(false);

    const formData = useSelector(state => state.form_details);

    const customCode = useSelector(state => state.finalReducers.new_product_code)
    // const [customCode, setCustomCode] = useState('');
    const handleCustomCodeGasoline = (data) => {
        gasolineCodes.unshift({ id: data.id, code: customCode.codeName, description: customCode.codeDescription, checked: true })
        dispatch(gasolineCodeAction(gasolineCodes))
    }

    const handleCustomCodeDiesel = (data) => {
        dieselCodes.unshift({ id: data.id, code: customCode.codeName, description: customCode.codeDescription, checked: true })
        dispatch(dieselCodeAction(dieselCodes))
    }

    const handleCustomCodeAviationFuel = (data) => {
        aviationFuel.unshift({ id: data.id, code: customCode.codeName, description: customCode.codeDescription, checked: true })
        dispatch(aviationFuelCodeAction(aviationFuel))
    }

    const handleCustomCodeLPG = (data) => {
        LPGCodes.unshift({ id: data.id, code: customCode.codeName, description: customCode.codeDescription, checked: true })
        dispatch(lpgCodeAction(LPGCodes))
    }


    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const handleFilteredResults = (filteredData) => {
        setFilteredResults(filteredData)
    }

    const handleSearchInput = (data) => {
        setSearchInput(data)
    }

    const dispatch = useDispatch();

    const [selectAllGas, setSelectAllGas] = useState(JSON.parse(localStorage.getItem('gasSelectAll')) === true);
    const [selectAll, setSelectAll] = useState(true);
    const handleSelectAll = (e) => {
        const codes = [];
        if (e.target.checked === true) {
            setSelectAll(true);
            setSelectAllGas(true)
            switch (type) {
                case '(Gasoline)':
                    gasolineCodes.map((data) => {
                        data.checked = true;
                    })
                    localStorage.setItem('gasSelectAll', true)
                    dispatch(gasolineCodeAction(gasolineCodes))
                    break;
                case '(Diesel)':
                    dieselCodes.map((data) => {
                        data.checked = true;
                    })
                    localStorage.setItem('dieselSelectAll', true)
                    dispatch(dieselCodeAction(dieselCodes))
                    break;
                case '(LPG)':
                    LPGCodes.map((data) => {
                        data.checked = true;
                    })
                    localStorage.setItem('lpgSelectAll', true)
                    dispatch(lpgCodeAction(LPGCodes))
                    break;
                case '(Aviation Fuel)':
                    aviationFuel.map((data) => {
                        data.checked = true;
                    })
                    localStorage.setItem('aviationSelectAll', true)
                    dispatch(aviationFuelCodeAction(aviationFuel))
                    break;
            }
        }
        else {
            setSelectAll(false);
            setSelectAllGas(false)
            switch (type) {
                case '(Gasoline)':
                    gasolineCodes.map((data) => {
                        data.checked = false;
                    })
                    localStorage.setItem('gasSelectAll', false)
                    dispatch(gasolineCodeAction(gasolineCodes))
                    break;
                case '(Diesel)':
                    dieselCodes.map((data) => {
                        data.checked = false;
                    })
                    localStorage.setItem('dieselSelectAll', false)
                    dispatch(dieselCodeAction(dieselCodes))
                    break;
                case '(LPG)':
                    LPGCodes.map((data) => {
                        data.checked = false;
                    })
                    localStorage.setItem('lpgSelectAll', false)
                    dispatch(lpgCodeAction(LPGCodes))
                    break;
                case '(Aviation Fuel)':
                    aviationFuel.map((data) => {
                        data.checked = false;
                    })
                    localStorage.setItem('aviationSelectAll', false)
                    dispatch(aviationFuelCodeAction(aviationFuel))
                    break;
            }

        }
    }

    const [openCustomCodeModal, setOpenCustomCodeModal] = useState(false);
    const handleAddCustomButton = () => {
        setOpenCustomCodeModal(true);
    }

    const handleModalClose = () => {
        setOpenCustomCodeModal(false)
    }
    let productType = '';

    useEffect(() => {
        if (searchInput.length <= 1) {
            switch (type) {
                case '(Gasoline)':
                    setFilteredResults(gasolineCodes)
                    break;
                case '(Diesel)':
                    setFilteredResults(dieselCodes)
                    break;
                case '(Aviation Fuel)':
                    setFilteredResults(aviationFuel)
                    break;
                case '(LPG)':
                    setFilteredResults(LPGCodes)
                    break;
            }
        }
    }, [searchInput])

    useEffect(async () => {
        const loginData = JSON.parse(localStorage.getItem('loginData'));
        const accessToken = loginData && loginData.accessToken;
        let addCustomCodeService;
        if (customCode.codeName !== undefined) {
            setLoader(true);
            switch (type) {
                case '(Gasoline)':
                    productType = 'gas';
                    break;
                case '(Diesel)':
                    productType = 'diesel';
                    break;
                case '(Aviation Fuel)':
                    productType = 'aviation fuel';
                    break;
                case '(LPG)':
                    productType = 'lpg';
                    break;
            }
            addCustomCodeService = await addProductService(accessToken, customCode, productType);
            addCustomCodeService = JSON.parse(addCustomCodeService)

            switch (type) {
                case '(Gasoline)':
                    if (addCustomCodeService.data && addCustomCodeService.data[0]) {
                        handleCustomCodeGasoline(addCustomCodeService.data[0]);
                    }
                    dispatch(addNewCodeAction({}))
                    break;
                case '(Diesel)':
                    if (addCustomCodeService.data && addCustomCodeService.data[0])
                        handleCustomCodeDiesel(addCustomCodeService.data[0]);
                    dispatch(addNewCodeAction({}))
                    break;
                case '(LPG)':
                    if (addCustomCodeService.data && addCustomCodeService.data[0])
                        handleCustomCodeLPG(addCustomCodeService.data[0]);
                    dispatch(addNewCodeAction({}))
                    break;
                case '(Aviation Fuel)':
                    if (addCustomCodeService.data && addCustomCodeService.data[0])
                        handleCustomCodeAviationFuel(addCustomCodeService.data[0]);
                    dispatch(addNewCodeAction({}))
                    break;
            }
            setLoader(false);

        }
    }, [customCode.codeName])

    useEffect(() => {

    }, [selectAll])

    useEffect(() => {
        let  commonSelectAll=true;
        switch (type) {
            case '(Gasoline)':
                commonSelectAll = localStorage.getItem('gasSelectAll');
                break;
            case '(Diesel)':
                commonSelectAll = localStorage.getItem('dieselSelectAll');
                break;
            case '(Aviation Fuel)':
                commonSelectAll = localStorage.getItem('aviationSelectAll');
                break;
            case '(LPG)':
                commonSelectAll = localStorage.getItem('lpgSelectAll');
                break;
        }
        if (commonSelectAll == 'true') {
            setSelectAllGas(true)
        }
        else {
            setSelectAllGas(false)
        }
    }, [])

    return (
        <div style={{ marginTop: '2%' }}>
            <Grid sx={{ display: 'flex' }}>
                <Grid sx={{ flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: 15 }}><strong>Customer Name</strong> : {customerName} </Typography>
                    <Typography sx={{ marginTop: '4%', fontSize: 15 }}><strong>Transaction timeline</strong> : {startDate} to {endDate} </Typography>
                </Grid>
                <Grid sx={{ flexDirection: 'column', marginLeft: '10%' }}>
                    <Typography sx={{ fontSize: 15 }}><strong>Product Description Code {type}</strong></Typography>
                    <div style={{ marginTop: '0.5%', display: 'flex', alignItems: 'center' }}>
                        <Button sx={{
                            marginTop: '5%', textTransform: 'none', color: 'white', backgroundColor: '#cc0000', "&:hover": {
                                backgroundColor: "#cc0000",
                            },
                        }} onClick={handleAddCustomButton}>Add Custom Code</Button>

                    </div>
                </Grid>
            </Grid>
            <Grid sx={{ marginTop: '8%' }}>
                <Grid sx={{ marginTop: '2%', display: 'flex' }}>
                    <Grid>
                        <FormControl>
                            <FormControlLabel control={<Checkbox
                                style={{ color: '#cc0000' }}
                                checked={selectAllGas}
                                onChange={(e) => handleSelectAll(e)} />} label={<span style={{ fontSize: 14 }}>Select All</span>} />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginLeft: 'auto' }}>
                        {type === '(Gasoline)' && <SearchComponent handleFilteredResults={handleFilteredResults} handleSearchInput={handleSearchInput} productCodes={gasolineCodes} />}
                        {type === '(Diesel)' && <SearchComponent handleFilteredResults={handleFilteredResults} handleSearchInput={handleSearchInput} productCodes={dieselCodes} />}
                        {type === '(LPG)' && <SearchComponent handleFilteredResults={handleFilteredResults} handleSearchInput={handleSearchInput} productCodes={LPGCodes} />}
                        {type === '(Aviation Fuel)' && <SearchComponent handleFilteredResults={handleFilteredResults} handleSearchInput={handleSearchInput} productCodes={aviationFuel} />}
                    </Grid>
                </Grid>

                <Box
                    sx={{ width: '100%', bgcolor: 'background.paper', marginTop: '2%', overflowY: 'scroll', height: '270px', border: '1px solid black' }}
                >
                    {type === '(Gasoline)' && searchInput.length > 1 ? (
                        filteredResults.map((data) => {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} enableTextField={true} productType={type} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        })
                    ) : (
                        type === '(Gasoline)' && gasolineCodes && gasolineCodes.map((data) => {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} enableTextField={true} selectAll={selectAll} productType={type} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        })
                    )}
                    {type === '(Diesel)' && searchInput.length > 1 ? (
                        filteredResults.map((data) => {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} enableTextField={true} productType={type} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        })
                    ) : (
                        type === '(Diesel)' && dieselCodes && dieselCodes.map((data) => {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} enableTextField={true} selectAll={selectAll} productType={type} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        })
                    )}


                    {type === '(Aviation Fuel)' && searchInput.length > 1 ? (
                        filteredResults.map((data) => {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} enableTextField={true} productType={type} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        })
                    ) : (
                        type === '(Aviation Fuel)' && aviationFuel && aviationFuel.map((data) => {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} enableTextField={true} selectAll={selectAll} productType={type} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        })
                    )}

                    {type === '(LPG)' && searchInput.length > 1 ? (
                        filteredResults.map((data) => {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} enableTextField={true} productType={type} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        })
                    ) : (
                        type === '(LPG)' && LPGCodes && LPGCodes.map((data) => {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} enableTextField={true} selectAll={selectAll} productType={type} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        })
                    )}
                </Box>
            </Grid>

            {
                openCustomCodeModal && <CustomCodeModal open={openCustomCodeModal} handleClose={handleModalClose} fuelType={type} />
            }

            {loader &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </div>
    )
}

export default FuelSelectionModal;