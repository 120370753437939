import { Box, Checkbox, Divider, FormControl, FormControlLabel, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import MultiSelectComponent from '../../components/MultiSelectComponent'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';
import { providerDiscountsAction } from '../../redux/actions/optimizationFormAction';
import SearchComponent from '../../components/SearchComponent'

function ProviderDiscounts() {
    const providerDiscounts = useSelector(state => state.finalReducers.provider_discounts);
    const dispatch = useDispatch();


    const handleProvidersSelected = (e, providerData) => {
        if (e.target.checked === false) {
            providerDiscounts.map((data) => {
                if (data.providerName === providerData.providerName) {
                    data.checked = false;
                }
            })
            dispatch(providerDiscountsAction(providerDiscounts))
        }
    }

    const [filteredResults, setFilteredResults] = useState(providerDiscounts)
    const handleFilteredResults = (filteredData) => {
        setFilteredResults(filteredData)
    }
    const [searchInput, setSearchInput] = useState('');
    const handleSearchInput = (data) => {
        setSearchInput(data)
    }

    const [selectAllProviders, setSelectAllProviders] = useState(JSON.parse(localStorage.getItem('providersSelectAll')) === true);
    const [selectAll, setSelectAll] = useState(true);
    const handleSelectAll = (e) => {
        if (e.target.checked === true) {
            setSelectAll(true)
            setSelectAllProviders(true);
            providerDiscounts.map((data) => {
                data.checked = true;
            })
            localStorage.setItem('providersSelectAll', true)
            dispatch(providerDiscountsAction(providerDiscounts))
        }
        else if (e.target.checked === false) {
            setSelectAll(false)
            setSelectAllProviders(false);
            providerDiscounts.map((data) => {
                data.checked = false;
            })
            localStorage.setItem('providersSelectAll', false)
            dispatch(providerDiscountsAction(providerDiscounts))
        }
        setFilteredResults(providerDiscounts)

    }


    // useEffect(() => {
    //     if (searchInput.length <= 1) {
    //         setFilteredResults(providerDiscounts)
    //     }
    // }, [searchInput])

    useEffect(() => {

    }, [selectAll])
    // useEffect(()=>{
    //     setFilteredResults(providerDiscounts)
    // },[handleSelectAll])
    useEffect(()=>{
        const providersSelectAll = localStorage.getItem('providersSelectAll');
        if (providersSelectAll == 'true') {
            setSelectAllProviders(true)
        }
        else {
            setSelectAllProviders(false)
        }
    },[])


    return (
        <Grid sx={{ marginTop: '5%' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>Provider Discounts</Typography>
            <Grid sx={{ marginTop: '2%',display:'flex' }}>
                <Grid>
                    <FormControl >
                        <FormControlLabel control={<Checkbox
                            style={{ color: '#cc0000' }}
                            checked={selectAllProviders}
                            onChange={(e) => handleSelectAll(e)} />} label={<span style={{ fontSize: 14 }}>Select All</span>} />
                    </FormControl>
                </Grid>
                <Grid sx={{marginLeft:'auto'}}>
                    <SearchComponent handleFilteredResults={handleFilteredResults} handleSearchInput={handleSearchInput} productCodes={providerDiscounts} />
                </Grid>
            </Grid>
            <Box
                sx={{ width: '100%', bgcolor: 'background.paper', marginTop: '2%', overflowY: 'scroll', height: '250px', border: '1px solid black' }}
            >
                {searchInput.length > 1 ? (
                    filteredResults.map((data) => {
                        if (data.providerName !== '' || data.providerCode !== '') {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} handleMultiselect={handleProvidersSelected} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        }
                    })
                ) : (

                    providerDiscounts.map((data) => {
                        if (data.providerName !== '' || data.providerCode !== '') {
                            return (
                                <span>
                                    <MultiSelectComponent data={data} handleMultiselect={handleProvidersSelected} />
                                    <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
                                </span>
                            )
                        }
                    })
                )
                }
            </Box>
        </Grid>
    )
}

export default ProviderDiscounts