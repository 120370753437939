import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getOptimizerHistoryService } from '../../services/optimizerHistory.service'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_URL } from '../../constants/constants';
import { DataGrid } from '@mui/x-data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function HomeCardComponent({ title, value }) {
    return (
        <>
            <Card sx={{ backgroundColor: '#ffffff', width: '13vw',boxShadow:'0px 0px 5px 5px rgba(0,0,0,0.05)'}}>
                <CardContent sx={{ width: '100%' }}>
                    <Typography sx={{ fontSize: '1.6vw', textAlign: 'center', fontWeight: 'bold', width: '100%', color: '#cc0000' }}>{value ? value.toLocaleString('en-US') : <CircularProgress sx={{color:'#cc0000'}} size={30}/>}</Typography>
                    <Typography sx={{ fontSize: '1.1vw', textAlign: 'center' }}>{title}</Typography>
                </CardContent>
            </Card>
        </>
    )
}


function HomePage() {
    const [optimizerHistoryData, setOptimizerHistoryData] = useState([]);
    const [loader, setLoader] = React.useState(false);

    const dateFormatter = (date) => {
        return moment(date).format('DD-MM-YYYY');
    }

    var styles = {
        tableCells: {
            fontSize: '1.1vw',
            color: "#ffffff"
        },
        tableCells2: {
            fontSize: '1vw',
        }
    }

    const optimizerColumns = [
        {
            field: 'customerName',
            headerName: 'Customer Name',
            width: 200,
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 130,
            renderCell: (params) => (
                moment(params.row.startDate).format('DD-MM-YYYY')
            )
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: 130,
            renderCell: (params) => (
                moment(params.row.endDate).format('DD-MM-YYYY')
            )
        },
        {
            field: 'Anticipated Savings',
            headerName: 'Anticipated Savings',
            width: 230,
            renderCell: (params) => (
                '$ ' + params.row.productSavings.reduce((a, b) => (a + b.savings), 0).toFixed(2)
            )
        }
    ];

    const messagesColumns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 260,
        },
        {
            field: 'body',
            headerName: 'Message',
            width: 330,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 130,
            renderCell: (params) => (
                moment(params.row.createdAt).format('DD-MM-YYYY')
            )
        },
    ]


    const navigate = useNavigate();
    const [dataCounts, setDataCounts] = useState([]);
    const [messagesData, setMessagesData] = useState([])

    useEffect(() => {
        const loginData = JSON.parse(localStorage.getItem('loginData'));
        const accessToken = loginData && loginData.accessToken;
        setLoader(true)
        axios.get(BACKEND_URL + '/api/v2/admin/analysis/recents?count=3', {
            headers: {
                'Authorization': "Bearer ".concat(accessToken)
            }
        })
            .then((res) => {
                setOptimizerHistoryData(res.data.data)
            })
            .catch((err)=>{
                throw err
            })
        axios.get(BACKEND_URL + '/api/v2/admin/dashboard/getStats', {
            headers: {
                'Authorization': "Bearer ".concat(accessToken)
            }
        })
            .then((res) => {
                console.log(res);
                setDataCounts(res.data.data);
            }).catch((err)=>{
                throw err
            })

        axios.get(BACKEND_URL + '/api/v2/admin/notifications/getAll?count=3&isUser=0', {
            headers: {
                'Authorization': "Bearer ".concat(accessToken)
            }
        })
            .then((res) => {
                setMessagesData(res.data.data)
                setLoader(false)
            }).catch((err)=>{
                throw err
            })
    }, [])

    return (
        <>
            <Grid sx={{ display: 'flex' }}>
                <Grid sx={{ marginTop: '1%', width: '100%' }} item xs={8}>
                    <Typography variant='h6' sx={{ fontSize: '1.5vw' }}><strong>Optimizer Overview</strong></Typography>
                    <Button sx={{ fontSize: '1vw', marginLeft: '53%', marginTop: '-5%', color: '#cc0000', fontWeight: 'bold', padding: '0.5%', textTransform: 'none',border: '1px solid red' }} variant='outlined' onClick={() => navigate('/optimizer')}>View More</Button>
                    <Box style={{ height: '45vh', width: '60%' }}>
                        {optimizerHistoryData.length ? (
                            <TableContainer component={Paper}>
                                <Table sx={{
                                    minWidth: 650, '& .MuiTableCell-root': {
                                        lineHeight: '0.5rem'
                                    }
                                }} aria-label="simple table">
                                    <TableHead sx={{
                                        backgroundColor: "#cc0000",
                                        fontSize: 16
                                    }}>
                                        <TableRow>
                                            <TableCell style={styles.tableCells}>Customer Name</TableCell>
                                            <TableCell style={styles.tableCells}>Start Date</TableCell>
                                            <TableCell style={styles.tableCells}>End Date</TableCell>
                                            <TableCell style={styles.tableCells}>Anticipated Savings</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {optimizerHistoryData && optimizerHistoryData.map((row) => (
                                            <TableRow
                                                key={row.customerName}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" style={styles.tableCells2}>
                                                    {row.customerName}
                                                </TableCell>
                                                <TableCell style={styles.tableCells2}>{dateFormatter(row.startDate)}</TableCell>
                                                <TableCell style={styles.tableCells2}>{dateFormatter(row.endDate)}</TableCell>
                                                <TableCell style={styles.tableCells2}>$ {row.productSavings.reduce((a, b) => (a + b.savings), 0).toFixed(2)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Grid sx={{ marginTop: '5%' }} container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center">
                                {!loader && <Typography sx={{ textAlign: 'center' }}>No Records Yet</Typography>}
                                {loader && <CircularProgress sx={{ marginTop: '2%',color:'#cc0000' }} size={40} />}
                            </Grid>
                        )}
                    </Box>

                </Grid>
                <Grid item xs={4} sx={{ marginLeft: '-33%', marginTop: '1%' }}>
                <Typography variant='h6' sx={{ fontSize: '1.5vw',paddingBottom:'7%' }}><strong>Quick Stats</strong></Typography>
                    <Grid sx={{ display: 'flex' }} columnGap={2}>
                        <HomeCardComponent title='Provider Count' value={dataCounts ? dataCounts['providerCount'] : 0} />
                        <HomeCardComponent title='Total Stations' value={dataCounts['totalStations']} />
                    </Grid>

                    <Grid sx={{ margin: '10% 0% 0% 0%', display: 'flex' }} columnGap={2}>
                        <Card sx={{ backgroundColor: '#ffffff', width: '130%',boxShadow:'0px 0px 5px 5px rgba(0,0,0,0.05)' }}>
                            <CardContent>
                                <Typography sx={{ fontSize: '1.6vw', textAlign: 'center', fontWeight: 'bold', width: '100%', color: '#cc0000' }}>{dataCounts['totalEstimatedSavings'] ?'$ '+ dataCounts['totalEstimatedSavings'].toLocaleString('en-US') : <CircularProgress sx={{color:'#cc0000'}} size={30}/>}</Typography>
                                <Typography sx={{ fontSize: '1.1vw', textAlign: 'center' }}>Total Estimated Savings</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ marginTop: '-50px' }}>
                <Typography variant='h6' sx={{ fontSize: '1.5vw' }}><strong>Messages Overview</strong></Typography>
                <Button sx={{ fontSize: '1vw', marginLeft: '53%', marginTop: '-5%', color: '#cc0000', fontWeight: 'bold', padding: '0.5%', textTransform: 'none',border: '1px solid red' }} variant='outlined' onClick={() => navigate('/notifications')}>View More</Button>
                <Box style={{ height: '30vh', width: '60%' }}>
                    {messagesData.length ? (
                        <TableContainer component={Paper}>
                            <Table sx={{
                                minWidth: 650, '& .MuiTableCell-root': {
                                    lineHeight: '0.5rem'
                                }
                            }} aria-label="simple table">
                                <TableHead sx={{
                                    backgroundColor: "#cc0000",
                                    fontSize: 16
                                }}>
                                    <TableRow>
                                        <TableCell style={styles.tableCells}>Title</TableCell>
                                        <TableCell style={styles.tableCells}>Message</TableCell>
                                        <TableCell style={styles.tableCells}>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {messagesData && messagesData.map((row) => (
                                        <TableRow
                                            key={row.title}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={styles.tableCells2} component="th" scope="row">
                                                {row.title.length < 25 ? row.title : row.title.substring(0, 25) + '...'}
                                            </TableCell>
                                            <TableCell style={styles.tableCells2}>{row.body.substring(0, 40)}...</TableCell>
                                            <TableCell style={styles.tableCells2}>{dateFormatter(row.createdAt)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                        : (
                            <>
                                <Grid sx={{ marginTop: '5%' }} container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center">
                                    {!loader && <Typography sx={{ textAlign: 'center' }}>No Messages Yet</Typography>}
                                    {loader && <CircularProgress sx={{ marginTop: '2%',color:'#cc0000' }} />}
                                </Grid>
                            </>
                        )}
                </Box>
            </Grid>
            {/* {loader &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            } */}
        </>

    )
}

export default HomePage
