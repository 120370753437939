import axios from "axios";
import { BACKEND_URL } from "../constants/constants";
export function getAllProvidersService(accessToken) {
    return new Promise(function (resolve, reject) {
        axios.get(BACKEND_URL+'/api/v2/admin/providers/getAll',{
            headers: {
                'Authorization': "Bearer ".concat(accessToken)
              }
        })
            .then((res) => {
                console.log(res.data);
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
                throw err;
            })
    })
}

export function getAllCustomerService(accessToken) {
    return new Promise(function (resolve, reject) {
        axios.get(BACKEND_URL+'/api/v2/admin/customers/getAll',{
            headers: {
                'Authorization': "Bearer ".concat(accessToken)
              }
        })
            .then((res) => {
                console.log(res.data);
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
                throw err;
            })
    })
}