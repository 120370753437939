const Auth = {
    isAuthenticated: localStorage.getItem('userEmail') !== null ? true : false,
    authenticate() {
        this.isAuthenticated = true;
    },
    signout() {
        localStorage.removeItem('userEmail');
        this.isAuthenticated = false;
    },
    getAuth() {
        console.log(this.isAuthenticated)
        return this.isAuthenticated;
    }
};
export default Auth;