import axios from "axios";
import {BACKEND_URL} from '../constants/constants'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function loginService(loginData) {
    return new Promise(function (resolve, reject) {
        axios.post(BACKEND_URL+'/api/v2/admin/login', loginData)
            .then((res) => {
                resolve(res.data);
            })
        .catch((err) => {
                toast.configure();
                reject(err);
                throw err;
            })
    })
}