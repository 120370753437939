import { ADD_NEW_CODE, AVIATIONFUEL_CODES, DIESEL_CODES, FILE_SELECTED, GASOLINE_CODES, GET_CUSTOMERS, LPG_CODES, OPTIMIZATION_FORM, PROVIDERS_DISCOUNTS, SEND_NOTIFICATION } from "../actions/actionTypes";

const initialState = {
    form_details: {},
    new_product_code: {},
    gasoline_codes: {},
    diesel_codes: {},
    lpg_codes:{},
    aviationfuel_codes:{},
    provider_discounts:{},
    file_selected:'No File Chosen',
    customer_data:{},
    notification_data:{}
}

const optimizeFormReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case OPTIMIZATION_FORM:
            return {
                ...state,
                form_details: payload
            }
            break;
        case ADD_NEW_CODE:
            return {
                ...state,
                new_product_code: payload
            }
            break;
        case GASOLINE_CODES:
            return {
                ...state,
                gasoline_codes: payload
            }
            break;
        case DIESEL_CODES:
            return {
                ...state,
                diesel_codes: payload
            }
            break;
        case LPG_CODES:
            return {
                ...state,
                lpg_codes: payload
            }
            break;
        case AVIATIONFUEL_CODES:
            return {
                ...state,
                aviationfuel_codes: payload
            }
            break;
        case PROVIDERS_DISCOUNTS:
            return {
                ...state,
                provider_discounts: payload
            }
            break;
        case FILE_SELECTED:
            return{
                ...state,
                file_selected:payload
            }
        case GET_CUSTOMERS:
            return{
                ...state,
                customer_data: payload
            }    
        case SEND_NOTIFICATION:
            return{
                ...state,
                notification_data:payload
            }    
        default:
            return state;
    }
}



export default optimizeFormReducer